<template>
  <div class="section-title">
    <h3 class="app-black--color" v-if="!!$slots.default">
      <slot></slot>
    </h3>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle'
}
</script>

<style scoped lang="scss">
  .section-title {
    position: relative;
    padding: 12px 0;
    margin-bottom: 20px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #2E3038;
      width: 100%;
    }
  }
  h3 {
    text-transform: uppercase;
    font-size: 1.12rem;
    line-height: 1.35rem;
  }
</style>
